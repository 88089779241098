import React, { useEffect, useContext } from "react";
import { ThreeContext } from '../three/ThreeContext';
import { useSprings,  animated, useTrail } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import SEO from '../components/seo';
import SplitTitle from '../components/SplitTitle';
import TransitionLink from 'gatsby-plugin-transition-link';
import anime from 'animejs/lib/anime.es.js';
import projectList from '../components/projectList';
import ChevronUp from  '../svgs/chevron-thin-up.svg';
import ChevronDown from  '../svgs/chevron-thin-down.svg';


const IndexPage = ( props ) => {
  let { mount} = props;
  const {pNum, setPnum, transition, setTransition, toProjectFn, rotateGroup, theta, setInProject, angleTicks, setAngleTicks} = useContext(ThreeContext);

  let count = angleTicks;

  useEffect(()=>{
    setInProject(false);
    setTransition(false);
    //toProjectFn.updateShaders(pNum);

  

  },[])

  useEffect(()=> {
    toProjectFn && toProjectFn.updateShaders(pNum);
  }, [toProjectFn])

  useEffect(()=> {
    //listening for transition = true when pNum changes lets us update shaders only when we rotate the index
    transition && pNum !== undefined && toProjectFn.updateShaders(pNum) ;
  }, [pNum]);


  function slideSwitch(delta, pNum){

    if(setPnum != undefined ){
      
      count = angleTicks ? angleTicks : 0;
      if(transition) return;
      setTransition(true);

      //set variable to the rotation property of the rotationGroup
      let rotgrp = rotateGroup.group.rotation;
      const deltaFin = Math.sign(delta);
      let newPnum = pNum + deltaFin;
      console.log('newPnum', newPnum)
      if(newPnum < 0 ) {
        setPnum(projectList.length - 1)
      } else if(newPnum > projectList.length -1 ) {
        setPnum(0)
      }else{
        setPnum(newPnum);
      }
      count += deltaFin;
      setAngleTicks(count);
      anime({
        targets: rotgrp,
        z: -theta * count,
        easing: 'easeInOutExpo',
        duration: 700,
        complete: () => {
          setTransition(false);

        }
      })
    }  
  }



  const gest = useGesture( {
    onDrag: ({direction: [,vy], axis}) => vy && axis === "y" && (slideSwitch(-vy, pNum)),
    onWheel: ({direction: [,vy], first }) => vy && first && (slideSwitch( vy, pNum)),
    onScroll: ({direction: [,vy], first }) => vy && first && (slideSwitch( vy, pNum))
  });
  
  const transFnAlt = (item) => {
    let props;
    //previously set 2nd condition to transitionStatus === 'entered'
    if(pNum === item.order && mount) {
      props = {
        to: { opacity: 1, subheading: -2.5, title: [0, 0], excerpt: 0},
        from: { opacity: 0, subheading: -5 , title: [-100, 10], excerpt: 300},
        delay: 350,
      }

    } else {
      props = {
        to: { opacity: 0, subheading: -5 , title: [-100, 10], excerpt: 300},
        from: { opacity: 0, subheading: -5 , title: [-100, 10], excerpt: 300},
      }
    }
    return props;
  }

  const springs = useSprings( projectList.length, projectList.map( item => transFnAlt(item)) );

  const trail = useTrail(4, {
    opacity: mount ? 1 : 0,
  })

  const clickToProject = () => {
    setTransition(true);
    setInProject(true);
    toProjectFn.transitionToProject(projectList[pNum].order, true);
  }




  return(
    <>
    <SEO title='Projects' location={props.location.pathname} />
    <div {...gest()} className="index-container">
      {
        springs.map( ({ opacity, subheading, title  }, i) => {
          let activeClass = pNum === i ? 'active' : '';
          return (
            <animated.div className={ 'block project-container ' + activeClass } key={i} style={{ opacity }} >
              <div className="project-info">
                {
                  projectList[i].services &&
                  <animated.h5 style={{ transform: subheading.interpolate( ty => `translateY(${ty}rem)`)}}>{projectList[i].services}</animated.h5>
                }
                  <TransitionLink
                    className='title-link'
                    to={projectList[i].url}
                    exit={{
                        length: 0.5,
                        trigger: () => { clickToProject() },
                      }}
                    entry={{
                      delay: 0.5 ,
                    }}
                  >
                  <animated.h2 style={{ transform: title.interpolate( (ty, sk) => `translateX(${ty}%) skewX(${sk}deg)`)}}><SplitTitle>{projectList[i].name}</SplitTitle></animated.h2>
                </TransitionLink>

                <div className="image-wrap">
                  <TransitionLink
                    to={projectList[i].url}
                    exit={{
                        length: 0.5,
                        trigger: () => { clickToProject() },
                      }}
                    entry={{
                      delay: 0.5 ,
                    }}
                  >
                    <img className="img-ref" src={projectList[i].image} alt=""/>
                  </TransitionLink>
                </div>
                {/* <animated.p style={{ transform: excerpt.interpolate( ty => `translateY(${ty}%)`)}}>{projectList[i].excerpt}</animated.p> */}
              </div>
            </animated.div>
          )
        })
      }
      <div className="index-count">
        <animated.div style={{opacity: trail[0].opacity}} className="arrow-wrap">
          <ChevronUp onClick={ () => slideSwitch(-1, pNum)} />
        </animated.div>
        <div className="fraction-wrap">
          <animated.span style={{opacity: trail[1].opacity}} className="current-project-num">{(pNum+1).toString().padStart(2,0)}</animated.span>
          <animated.span style={{opacity: trail[2].opacity}} className="divider">|</animated.span>
          <animated.span style={{opacity: trail[3].opacity}} className="total-project-num">{projectList.length.toString().padStart(2,0)}</animated.span>
        </div>
        <animated.div style={{opacity: trail[0].opacity}} className="arrow-wrap">
          <ChevronDown onClick={ () => slideSwitch(1, pNum)} />
        </animated.div>
      </div>
      <animated.div style={{opacity: trail[0].opacity}} className="title-wrap">
        <h1 className="outline-heading">Projects</h1>
      </animated.div>
    </div>
  </>
)
  

}
export default IndexPage;

import React from 'react';
import './SplitTitle.scss';

const titleFormat = (title) => {
  let newTitle = title.split(' ');
  let output = '';
  newTitle.forEach( (word, i) => {
    output += `<span>${word} </span>`;
  })
  return output;
}

const SplitTitle = ({children}) => {
  return (
    <>
      <div className="split-title" dangerouslySetInnerHTML={{ __html: titleFormat(children)}}></div>

    </>
  )


}

export default SplitTitle;